import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import HighTechChart from './HighTechChart';
import { 
  Box,
  AppBar,
  Toolbar,
  Typography,
  Card,
  CardContent,
  Container,
  Grid,
  Button,
  ThemeProvider,
  createTheme
} from '@mui/material';
import TeamMapSection from './TeamMapSection';

// Custom theme to match design requirements
const theme = createTheme({
  palette: {
    primary: {
      main: '#faf9f7',
      dark: '#e9ecef',
      light: '#faf9f7',
    },
    secondary: {
      main: '#5885AF',
      dark: '#4A7497',
      light: '#7eb0eb',
    },
    background: {
      default: '#faf9f7',
      paper: '#ffffff',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#7f8c8d',
    },
  },
  typography: {
    fontFamily: '"Inter", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 300,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 300,
      letterSpacing: '-0.00833em',
    },
    body1: {
      fontSize: '1rem',
      lineHeight: 1.5,
      letterSpacing: '0.00938em',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: 8,
          padding: '8px 24px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.04)',
          transition: 'transform 0.2s ease-in-out',
          '&:hover': {
            transform: 'translateY(-4px)',
          },
        },
      },
    },
  },
});

const App = () => {
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  });

  useEffect(() => {
    const targetDate = new Date('December 20, 2024 00:00:00').getTime();

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const difference = targetDate - now;

      const days = Math.floor(difference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      setTimeLeft({ days, hours, minutes, seconds });

      if (difference < 0) {
        clearInterval(interval);
        setTimeLeft({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence mode="wait">
        <Box sx={{ flexGrow: 1, bgcolor: 'primary.main' }} component="main">
          {/* Navigation */}
          <AppBar position="fixed" elevation={0} color="transparent">
            <Toolbar>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
                <Button color="inherit">About</Button>
                <Button color="inherit">Data</Button>
                <Button color="inherit">Research</Button>
              </Box>
            </Toolbar>
          </AppBar>   
          {/* Hero Section */}  
          <Box
            sx={{
              height: '100vh',
              width: '100%',
              display: 'flex',
              flexDirection: 'column', 
              alignItems: 'center',    
              justifyContent: 'center',  
              bgcolor: 'primary.main',
              position: 'relative',    
            }}
          >
            <motion.div
              animate={{
                scale: [1, 1.05, 1],
                transition: {
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse",
                  ease: [0.4, 0, 0.2, 1],
                }
              }}
              style={{
                width: '100%',
                textAlign: 'center'    
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontSize: 'clamp(10rem, 25vw, 15rem)',
                  lineHeight: 1,
                  fontFamily: 'Impact, sans-serif',
                  textAlign: 'center',
                  mx: 'auto',
                  mb: 4        
                }}
              >
                ObjectiveTruth
              </Typography>
            </motion.div>
       
            {/* Countdown Timer */}
            <Typography
              component="h2"
              sx={{
                fontSize: 'clamp(4rem, 10vw, 6rem)',  
                lineHeight: 1,
                fontFamily: 'Impact, sans-serif',
                textAlign: 'center',
                mx: 'auto'
              }}
            >
              {String(timeLeft.days).padStart(2, '0')}:
              {String(timeLeft.hours).padStart(2, '0')}:
              {String(timeLeft.minutes).padStart(2, '0')}:
              {String(timeLeft.seconds).padStart(2, '0')}
            </Typography>
            <Typography
              component="h3"
              sx={{
                fontSize: 'clamp(1.5rem, 3vw, 2rem)',
                lineHeight: 1.2,
                fontFamily: 'Impact, sans-serif',
                textAlign: 'center',
                mx: 'auto',
                mt: 8,  
                color: 'text.secondary'
              }}
            >
              Uncovering Digital Patterns December 20th
            </Typography>
          </Box>
         
          {/* Feature Cards */}
          <Container sx={{ py: 8 }}>
            <Grid container spacing={4}>
              {[
                {
                  title: 'Evolution of Online Discourse',
                  text: 'Discover how digital conversations have evolved from simple exchanges to complex, multi-layered interactions. Our analysis reveals emerging patterns in communication styles, engagement behaviors, and viral phenomena across social platforms.'
                },
                {
                  title: 'Algorithm Impact Analysis',
                  text: 'Explore the hidden mechanics of social media algorithms and their influence on information flow. Through advanced data visualization, we illuminate how these invisible systems shape our online experience and collective understanding.'
                },
                {
                  title: 'Cross-Platform Patterns',
                  text: 'Uncover the interconnected nature of social media through our cross-platform analysis. See how information travels, transforms, and impacts across different digital spaces, revealing crucial patterns in our networked world.'
                }
              ].map(({title, text}) => (
                <Grid item xs={12} md={4} key={title}>
                  <Card>
                    <CardContent>
                      <Typography variant="h5" gutterBottom>
                        {title}
                      </Typography>
                      <Typography variant="body1" color="text.secondary">
                        {text}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Container>

          {/* Data Section */}
          <Box sx={{ bgcolor: 'primary.main', py: 8 }}>
            <Container>
              <Typography variant="h2" gutterBottom>
              </Typography>
              <HighTechChart />
            </Container>
          </Box>
          <TeamMapSection />
        </Box>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default App;