import React from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  TextField,
  Button
} from '@mui/material';
import { motion } from 'framer-motion';

const TeamMapSection = () => {
  const locations = [
    { country: 'Sweden', coords: [60, 18] },
    { country: 'Denmark', coords: [56, 10] },
    { country: 'Russia', coords: [55, 37] },
    { country: 'Luxembourg', coords: [49.8, 6.1] },
    { country: 'Netherlands', coords: [52.3, 4.9] },
    { country: 'Hungary', coords: [47.5, 19.1] },
    { country: 'Malaysia', coords: [3.1, 101.7] },
    { country: 'South Africa', coords: [-26.2, 28.0] },
    { country: 'Lebanon', coords: [33.9, 35.5] },
    { country: 'India', coords: [20.6, 79.0] }
  ];

  const destination = { country: 'Snekkersten', coords: [56.0377, 12.5958] };

  // Convert geographic coordinates to SVG coordinates
  const geoToSvgCoords = (lat, lon) => {
    // Map coordinates to SVG space
    // SVG viewBox is 800x400, matching approximate aspect ratio of the map
    const x = ((lon + 180) * (800 / 360));
    const y = ((90 - lat) * (400 / 180));
    return [x, y];
  };

  return (
    <Box sx={{ bgcolor: 'primary.main', py: 8 }}>
      <Container>
        {/* Team Section Header */}
        <Box sx={{ textAlign: 'center', mb: 6 }}>
          <Typography
            component="h2"
            sx={{
              fontSize: 'clamp(2rem, 5vw, 3rem)',
              lineHeight: 1.2,
              fontFamily: 'Impact, sans-serif',
              mb: 2
            }}
          >
            The Team
          </Typography>
          <Typography
            component="h3"
            sx={{
              fontSize: 'clamp(1.2rem, 2.5vw, 1.5rem)',
              lineHeight: 1.2,
              fontFamily: 'Impact, sans-serif',
              color: 'secondary.main',
              mb: 2
            }}
          >
            People are coming from all over the world
          </Typography>
          <Typography variant="body1" color="text.secondary">
            From across the globe, the brightest minds in AI and technology are gathering. 
            They've left everything behind to be part of this project.
          </Typography>
        </Box>

        {/* Interactive Map */}
        <Card sx={{ mb: 8 }}>
          <CardContent>
            <Box sx={{ position: 'relative', width: '100%', pb: '50%' }}>
              <Box
                component="img"
                src="/world-map.png" // Make sure to add the map image to your public folder
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain'
                }}
              />
              <motion.svg
                viewBox="0 0 800 400"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%'
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 1 }}
              >
                {/* Draw locations and connections */}
                {locations.map((loc, i) => {
                  const [x, y] = geoToSvgCoords(loc.coords[0], loc.coords[1]);
                  const [destX, destY] = geoToSvgCoords(destination.coords[0], destination.coords[1]);
                  
                  return (
                    <motion.g
                      key={i}
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ delay: i * 0.2 }}
                    >
                      {/* Curved connection line */}
                      <path
                        d={`M ${x},${y} Q ${(x + destX) / 2},${Math.min(y, destY) - 20} ${destX},${destY}`}
                        fill="none"
                        stroke="#5885AF"
                        strokeWidth="2"
                        opacity="0.5"
                      >
                        <animate
                          attributeName="stroke-dashoffset"
                          from="100"
                          to="0"
                          dur="2s"
                          repeatCount="indefinite"
                        />
                      </path>
                      
                      {/* Location dot */}
                      <circle
                        cx={x}
                        cy={y}
                        r="4"
                        fill="#5885AF"
                      >
                        <animate
                          attributeName="r"
                          values="4;6;4"
                          dur="2s"
                          repeatCount="indefinite"
                        />
                      </circle>
                      
                      {/* Country label */}
                      <text
                        x={x}
                        y={y - 10}
                        fontSize="12"
                        textAnchor="middle"
                        fill="#2c3e50"
                        style={{
                          textShadow: '1px 1px 2px rgba(255,255,255,0.8)'
                        }}
                      >
                        {loc.country}
                      </text>
                    </motion.g>
                  );
                })}
                
                {/* Destination marker */}
                <motion.circle
                  cx={geoToSvgCoords(destination.coords[0], destination.coords[1])[0]}
                  cy={geoToSvgCoords(destination.coords[0], destination.coords[1])[1]}
                  r="6"
                  fill="#4A7497"
                  animate={{
                    r: [6, 8, 6],
                    opacity: [1, 0.7, 1]
                  }}
                  transition={{
                    duration: 2,
                    repeat: Infinity
                  }}
                />
              </motion.svg>
            </Box>
          </CardContent>
        </Card>

        {/* Contact Form remains the same */}
        <Card>
          <CardContent>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
              <Typography
                component="h2"
                sx={{
                  fontSize: 'clamp(1.5rem, 3vw, 2rem)',
                  lineHeight: 1.2,
                  fontFamily: 'Impact, sans-serif',
                  mb: 2
                }}
              >
                Do you want to join the project?
              </Typography>
              <Typography variant="h6" color="text.secondary">
                Contact Christine@digitalops.dk
              </Typography>
            </Box>
            
            <Box component="form" sx={{ maxWidth: 600, mx: 'auto' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Your Name"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Your Email"
                    type="email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Your Message"
                    multiline
                    rows={4}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    size="large"
                    sx={{ py: 1.5 }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
};

export default TeamMapSection;