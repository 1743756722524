import React from 'react';
import { AreaChart, Area, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

const HighTechChart = () => {
  const data = [
    { name: 'Neural', value: 8420, secondary: 6240 },
    { name: 'Deep Learn', value: 7300, secondary: 5800 },
    { name: 'Pattern', value: 9500, secondary: 7200 },
    { name: 'Cognitive', value: 6780, secondary: 4908 },
    { name: 'Quantum', value: 8890, secondary: 6800 },
    { name: 'Synthesis', value: 7390, secondary: 5300 },
  ];

  return (
    <div style={{ 
      backgroundColor: '#faf9f7', 
      padding: '1.5rem', 
      borderRadius: '0.75rem',
      boxShadow: '0 8px 32px rgba(88,133,175,0.1)',
      border: '1px solid rgba(88,133,175,0.1)'
    }}>
      <ResponsiveContainer width="100%" height={400}>
        <AreaChart data={data}>
          <defs>
            <linearGradient id="colorPrimary" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#5885AF" stopOpacity={0.9}/>
              <stop offset="95%" stopColor="#5885AF" stopOpacity={0.2}/>
            </linearGradient>
            <linearGradient id="colorSecondary" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#4A7497" stopOpacity={0.7}/>
              <stop offset="95%" stopColor="#4A7497" stopOpacity={0.2}/>
            </linearGradient>
            <filter id="glow">
              <feGaussianBlur stdDeviation="3" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          
          <CartesianGrid 
            strokeDasharray="3 3" 
            stroke="rgba(74,116,151,0.1)"
            filter="url(#glow)"
          />
          
          <XAxis 
            dataKey="name" 
            stroke="#2c3e50"
            tick={{ fill: '#2c3e50' }}
            tickLine={{ stroke: '#5885AF' }}
          />
          
          <YAxis 
            stroke="#2c3e50"
            tick={{ fill: '#2c3e50' }}
            tickLine={{ stroke: '#5885AF' }}
          />
          
          <Tooltip 
            contentStyle={{
              backgroundColor: 'rgba(255,255,255,0.95)',
              border: '1px solid rgba(88,133,175,0.2)',
              borderRadius: '8px',
              boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
              padding: '10px'
            }}
            itemStyle={{
              color: '#2c3e50'
            }}
            labelStyle={{
              color: '#5885AF',
              fontWeight: 'bold'
            }}
          />
          
          <Area 
            type="monotone" 
            dataKey="value" 
            stroke="#5885AF"
            strokeWidth={3}
            fill="url(#colorPrimary)"
            filter="url(#glow)"
          />
          
          <Area 
            type="monotone" 
            dataKey="secondary" 
            stroke="#4A7497"
            strokeWidth={2}
            fill="url(#colorSecondary)"
            filter="url(#glow)"
          />
        </AreaChart>
      </ResponsiveContainer>
      <div style={{
        textAlign: 'center',
        marginTop: '1rem',
        color: '#2c3e50',
        fontSize: '0.9rem',
        opacity: 0.8
      }}>
        AI Pattern Recognition Metrics
      </div>
    </div>
  );
};

export default HighTechChart;